const colors = {
  //TODO: add more colors as needed
  primary: "#1D4D99",
  disabled: "#B0B0B0",
  success: "#00AA00",
  error: "#AA0000",
  borders: "#888888",
  secondary: "#000000",
};

module.exports = colors;
