"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsAddingToBasket = exports.setCheckedOutBasket = exports.setDeliveryMethod = exports.setBasket = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var get_basket_thunk_1 = require("./thunks/get_basket_thunk");
var slice = (0, toolkit_1.createSlice)({
    name: "basket",
    initialState: {
        isAddingToBasket: false,
        payment_identifier: "",
        basket: null,
        product_list: [],
        delivery_method: "delivery",
        checkout_cookie: "",
    },
    reducers: {
        setBasket: function (state, action) {
            state.basket = action.payload;
        },
        setCheckedOutBasket: function (state, action) {
            state.checkout_cookie = action.payload;
        },
        // setPaymentIdentifiers: (state, action) => {
        //   state.payment_identifier = action.payload.payment_identifier;
        // },
        setDeliveryMethod: function (state, action) {
            state.delivery_method = action.payload;
        },
        setIsAddingToBasket: function (state, action) {
            state.isAddingToBasket = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(get_basket_thunk_1.get_basket_thunk.fulfilled, function (state, action) {
            console.log("get_basket_thunk.fulfilled", action);
            state.isAddingToBasket = false;
        })
            .addCase(get_basket_thunk_1.get_basket_thunk.rejected, function (state, action) {
            console.log("get_basket_thunk.rejected", action);
            state.isAddingToBasket = false;
        })
            .addCase(get_basket_thunk_1.get_basket_thunk.pending, function (state, action) {
            console.log("get_basket_thunk.pending", action);
            state.isAddingToBasket = true;
        });
    },
});
exports.setBasket = (_a = slice.actions, _a.setBasket), 
//setPaymentIdentifiers,
exports.setDeliveryMethod = _a.setDeliveryMethod, exports.setCheckedOutBasket = _a.setCheckedOutBasket, exports.setIsAddingToBasket = _a.setIsAddingToBasket;
exports.default = slice.reducer;
