"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var user_1 = require("../../reducers/slices/user");
var SignUpPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(""), nameMessage = _b[0], setNameMessage = _b[1];
    var _c = (0, react_1.useState)(""), surnameMessage = _c[0], setSurnameMessage = _c[1];
    var _d = (0, react_1.useState)(""), emailMessage = _d[0], setEmailMessage = _d[1];
    var _e = (0, react_1.useState)(""), phoneMessage = _e[0], setPhoneMessage = _e[1];
    var _f = (0, react_1.useState)(""), passwordMessage = _f[0], setPasswordMessage = _f[1];
    var _g = (0, react_1.useState)(""), confirmPasswordMessage = _g[0], setConfirmPasswordMessage = _g[1];
    var _h = (0, react_1.useState)(""), birthdayMessage = _h[0], setBirthdayMessage = _h[1];
    var _j = (0, react_1.useState)(""), agreesMessage = _j[0], setAgreesMessage = _j[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var onControlChanged = function (name, value) {
        var _a;
        console.log(name, value);
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { user: __assign(__assign({}, user.user), (_a = {}, _a[name] = value, _a)) })));
    };
    var validate = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneMessage("");
        setPasswordMessage("");
        setConfirmPasswordMessage("");
        setBirthdayMessage("");
        setAgreesMessage("");
        if (!user.user.name) {
            messageCount++;
            setNameMessage("Name is required.");
        }
        if (!user.user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!user.user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.user.cell_number) {
            messageCount++;
            setPhoneMessage("Phone Number is required.");
        }
        if (user.user.cell_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(user.user.cell_number, "ZA");
            if (!isValidPhoneNumber) {
                messageCount++;
                setPhoneMessage("Invalid Phone Number.");
            }
        }
        if (!user.user.password) {
            messageCount++;
            setPasswordMessage("Password is required.");
        }
        if (!user.user.confirm_password) {
            messageCount++;
            setConfirmPasswordMessage("Confirm Password is required.");
        }
        else {
            if (user.user.password != user.user.confirm_password) {
                messageCount++;
                setConfirmPasswordMessage("Passwords don't match.");
            }
        }
        if (!user.user.birthday) {
            messageCount++;
            setBirthdayMessage("Birthday is required.");
        }
        if (!user.user.agrees_terms_and_conditions) {
            messageCount++;
            setAgreesMessage("Must agree to our terms and conditions.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col w-96 h-full justify-center overflow-y-auto" },
        react_1.default.createElement("div", { className: "flex flex-col gap-y-4 overflow-auto" },
            react_1.default.createElement("div", { className: "text-3xl font-bold" }, "Create your account"),
            react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: user.user.name, errorText: nameMessage, onChange: function (v) { return onControlChanged("name", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: user.user.surname, errorText: surnameMessage, onChange: function (v) { return onControlChanged("surname", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: user.user.email, errorText: emailMessage, onChange: function (v) { return onControlChanged("email", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Phone Number", value: user.user.cell_number, errorText: phoneMessage, onChange: function (v) { return onControlChanged("cell_number", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", value: user.user.password, errorText: passwordMessage, type: "password", onChange: function (v) { return onControlChanged("password", v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm Password", value: user.user.confirm_password, errorText: confirmPasswordMessage, type: "password", onChange: function (v) { return onControlChanged("confirm_password", v); } }),
                react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "Birthday", errorText: birthdayMessage, value: (_a = user.user.birthday) !== null && _a !== void 0 ? _a : null, onChange: function (v) { return onControlChanged("birthday", v.toString()); } })),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Receive Communication?", onChange: function (v) { return onControlChanged("get_communication", v); } }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "I have read and agree to the Terms of Service", errorText: agreesMessage, onChange: function (v) { return onControlChanged("agrees_terms_and_conditions", v); } }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Account", backgroundColor: "bg-primary", loading: user.is_loading, onClick: function () {
                    if (validate()) {
                        dispatch((0, user_1.sign_up_thunk)());
                    }
                } }),
            react_1.default.createElement("div", { className: "text-center" },
                "Already have an account?",
                " ",
                react_1.default.createElement("span", { className: "hover:underline cursor-pointer text-primary", onClick: function () {
                        dispatch((0, main_1.setDrawerPage)("login"));
                        // navigate(`${rootPath}/login`);
                    } }, "Sign in")))));
};
exports.SignUpPage = SignUpPage;
