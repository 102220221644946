"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
// import { formatMoney } from "doshx_controls_web/lib/utilities";
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_product_1 = require("../../../api/calls/product/get_product");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var add_product_thunk_1 = require("../../../reducers/slices/basket/thunks/add_product_thunk");
var selection_1 = require("../../../reducers/slices/selection");
var constants_1 = require("./constants");
var ProductPage = function () {
    // const details: IProductPage | undefined = sampleDetails;
    var params = (0, react_router_dom_1.useParams)();
    var productId = params.productId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }), basket = _a.basket, isAddingToBasket = _a.isAddingToBasket;
    var selection = (0, react_redux_1.useSelector)(function (state) { return state.selectionState; }).selection;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)({
        _id: "",
        sku: "",
        title: "",
        sub_title: "",
        description: "",
        price: "0",
        promotion_price: "0",
        on_promotion: false,
        image_url: "",
        status: "",
        image_list: [],
        rating: {
            average: "0",
            count: "0",
        },
    }), retrievedProduct = _b[0], setRetrievedProduct = _b[1];
    var _c = (0, react_1.useState)(false), isAddingToCart = _c[0], setIsAddingToCart = _c[1];
    var _d = (0, react_1.useState)([]), recentlyViewed = _d[0], setRecentlyViewed = _d[1];
    var _e = (0, react_1.useState)(1), quantity = _e[0], setQuantity = _e[1];
    var formatMoney = doshx_controls_web_1.Utilities.formatMoney;
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = selection.selectedProduct) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : productId) {
            (0, get_product_1.getProduct)({
                _id: (_d = (_c = selection.selectedProduct) === null || _c === void 0 ? void 0 : _c._id) !== null && _d !== void 0 ? _d : productId,
                user_id: user.user._id,
            }).then(function (response) {
                console.log("Product details fetched", response);
                setRetrievedProduct(response.content);
            });
        }
        else {
            navigate(-1);
        }
    }, [productId]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.user._id) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                console.log("Recent products fetched", response);
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    // useEffect(() => {
    // }, []);
    var generatePriceDisplay = function () {
        if (retrievedProduct.on_promotion) {
            // const discountedPrice = (parseFloat(retrievedProduct.price) * parseFloat(retrievedProduct.promotion_price)) / 100;
            return (react_1.default.createElement("div", { className: "flex flex-row gap-8 items-end" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                        value: retrievedProduct.promotion_price,
                        decimalPlaces: 2,
                    }), size: "xlarge" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                        value: retrievedProduct.price,
                        decimalPlaces: 2,
                    }) })));
        }
        else {
            return (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                    value: retrievedProduct.price,
                    decimalPlaces: 2,
                }), size: "xlarge" }));
        }
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "self-center" },
                    react_1.default.createElement(doshx_controls_web_1.GalleryControl, { images: retrievedProduct.image_list && retrievedProduct.image_list.length > 0
                            ? retrievedProduct.image_list.map(function (item, idx) {
                                return { id: idx.toString(), url: item.url };
                            })
                            : [{ id: "1", url: retrievedProduct.image_url }] })),
                react_1.default.createElement("div", { className: "flex flex-col flex-1 gap-8 lg:self-start" },
                    react_1.default.createElement("div", { className: "flex flex-col " },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SKU: ".concat(retrievedProduct.sku) }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(retrievedProduct.title), size: "2xlarge" })),
                    generatePriceDisplay(),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: retrievedProduct.sub_title }),
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        retrievedProduct.status == constants_1.OUT_OF_STOCK ? (react_1.default.createElement(doshx_controls_web_1.BoxControl //
                        , { addPadding: true, borderColor: "border-secondary" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl //
                            , { label: "OUT OF STOCK", textColor: "text-error" }))) : (react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-primary", textColor: "text-primary", iconColor: "text-primary", value: quantity, onChange: function (v) {
                                setQuantity(v);
                            } })),
                        react_1.default.createElement("div", { className: "flex-1" }),
                        retrievedProduct.status != constants_1.OUT_OF_STOCK && (react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add To Cart", loading: isAddingToBasket, backgroundColor: "bg-primary", onClick: function () {
                                    dispatch((0, add_product_thunk_1.add_product_thunk)({
                                        product: retrievedProduct,
                                        quantity: quantity,
                                    }));
                                } })))))),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "w-full" },
                    react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: {
                            tabs: {
                                indicatorColor: "bg-primary",
                                backgroundColor: "bg-gray-100",
                            },
                        }, tabs: [
                            { title: "Description", content: react_1.default.createElement("div", { className: "p-4" }, retrievedProduct.description) },
                            // { title: "Specification", content: <div>Content 2</div> },
                            // { title: "Discussion", content: <div>Content 3</div> },
                            { title: "Reviews", content: react_1.default.createElement("div", { className: "p-4" }, "Coming soon...") },
                        ] }))),
            recentlyViewed && recentlyViewed.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselSmallControl, { category: {
                    name: "Recently viewed",
                }, products: recentlyViewed.map(function (product) {
                    return {
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                    };
                }), onClick: function (product) {
                    console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                } })))));
};
exports.ProductPage = ProductPage;
// const sampleDetails: IProductPage = {
//   sku: "123456",
//   title: "Test Title",
//   rating: {
//     average: 3,
//     count: 211,
//   },
//   sold: 17,
//   viewed: 333,
//   price: 1001.011,
//   discount: 50, // 0 - 100
//   description: "Test Product 1 description",
// };
