"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileAddressWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var address_list_1 = require("../../../api/calls/user/address_list");
var add_user_address_1 = require("../../../api/calls/user/add_user_address");
var delete_address_1 = require("../../../api/calls/user/delete_address");
var main_1 = require("../../../reducers/slices/main");
var ProfileAddressWidget = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(false), isEditing = _b[0], setIsEditing = _b[1];
    // const [selectedPlaceDetails, setSelectedPlaceDetails] = useState<any>(null);
    // const [selectedPlace, setSelectedPlace] = useState<any>(null);
    var _c = (0, react_1.useState)(), selectedAddress = _c[0], setSelectedAddress = _c[1];
    var _d = (0, react_1.useState)([]), addressList = _d[0], setAddressList = _d[1];
    var _e = (0, react_1.useState)(""), newAddressName = _e[0], setNewAddressName = _e[1];
    var _f = (0, react_1.useState)(""), addressLabelErrorText = _f[0], setAddressLabelErrorText = _f[1];
    var _g = (0, react_1.useState)(-1), deletingIndex = _g[0], setDeletingIndex = _g[1];
    var _h = (0, react_1.useState)(false), isSaving = _h[0], setIsSaving = _h[1];
    var _j = (0, react_1.useState)(false), isDeleting = _j[0], setIsDeleting = _j[1];
    var _k = (0, react_1.useState)(true), addressIsResidential = _k[0], setAddressIsResidential = _k[1];
    var _l = (0, react_1.useState)(""), businessName = _l[0], setBusinessName = _l[1];
    var _m = (0, react_1.useState)(""), complexOrBuildingDetails = _m[0], setComplexOrBuildingDetails = _m[1];
    (0, react_1.useEffect)(function () {
        getAddresses();
    }, []);
    var getAddresses = function () {
        var _a;
        (0, address_list_1.address_list)({
            user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
        }).then(function (response) {
            if (response.success) {
                setAddressList(response.content.addresses);
            }
            else {
                dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    };
    // useEffect(() => {
    //   if (selectedPlaceDetails?.geometry) {
    //     setSelectedAddress({
    //       full_address: selectedPlaceDetails.formatted_address,
    //       longitude: selectedPlaceDetails.geometry?.location?.lng?.toString(),
    //       latitude: selectedPlaceDetails.geometry?.location?.lat?.toString(),
    //     });
    //   }
    // }, [selectedPlaceDetails]);
    // useEffect(() => {
    //   if (selectedPlace) {
    //     placeiddetails(selectedPlace.place_id).then((response) => {
    //       if (response.success) {
    //         setSelectedPlaceDetails({
    //           ...selectedPlace,
    //           ...response.content,
    //         });
    //       } else {
    //         dispatch(
    //           setMainModal({
    //             title: "Something went wrong!",
    //             messages: [response.message],
    //           }),
    //         );
    //       }
    //     });
    //   }
    // }, [selectedPlace]);
    var validateAddress = function () {
        var messages = [];
        var errorCount = 0;
        setAddressLabelErrorText("");
        if (!selectedAddress) {
            messages.push("Please select an address. When you searched, please click on a prediction to select it.");
        }
        if (!newAddressName) {
            errorCount++;
            setAddressLabelErrorText("Please enter a label for your new address.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMainModal)({ title: "Address selection needed!", messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    var validateDeliveryAddressDetails = function () {
        var messages = [];
        var errorCount = 0;
        if (!addressIsResidential && !businessName) {
            errorCount++;
            messages.push("Please add your Business name to continue.");
        }
        if (!complexOrBuildingDetails) {
            messages.push("Please add your Complex/Building details to continue.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMainModal)({ title: "".concat(addressIsResidential ? "Residential details needed!" : "Business details needed!"), messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4 border border-borders rounded-lg" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Addresses", size: "large", textColor: "text-black" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faAdd, backgroundColor: isEditing ? "bg-success" : "bg-primary", iconColor: "text-white", size: "xsmall", loading: isSaving, onClick: function () {
                        var _a, _b, _c, _d;
                        if (isEditing) {
                            if (validateAddress()) {
                                setIsSaving(true);
                                (0, add_user_address_1.add_user_address)({
                                    _id: (_a = user === null || user === void 0 ? void 0 : user.user._id) !== null && _a !== void 0 ? _a : "",
                                    label: newAddressName,
                                    full_address: (_b = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) !== null && _b !== void 0 ? _b : "",
                                    latitude: (_c = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.latitude) !== null && _c !== void 0 ? _c : "",
                                    longitude: (_d = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.longitude) !== null && _d !== void 0 ? _d : "",
                                    is_residential: addressIsResidential,
                                    business_name: businessName,
                                    complex_building_details: complexOrBuildingDetails,
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        setIsEditing(false);
                                        getAddresses();
                                    }
                                    else {
                                        dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: [response.message] }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-primary", iconColor: "text-white", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        isEditing ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, 
                // value={address}
                required: true, placeholder: "Search & select address...", 
                // label="Search Address"
                size: "small", onSelectionChange: function (value) {
                    setSelectedAddress({
                        full_address: value.fullAddress,
                        longitude: value.coordinates.lng,
                        latitude: value.coordinates.lat,
                    });
                } }),
            react_1.default.createElement("div", null, (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) && (react_1.default.createElement("div", { className: "flex flex-col gap-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", size: "small", borderColor: "border-borders", onChange: function (v) { return setNewAddressName(v); }, errorText: addressLabelErrorText }),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedAddress.full_address, textColor: "text-black", size: "xsmall" }))))),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Is this address for a residential or business property?", textColor: "text-secondary" }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Residential", checkedColor: "text-primary", uncheckedColor: "text-secondary", value: addressIsResidential, onChange: function (v) {
                        setAddressIsResidential(v);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Business", checkedColor: "text-primary", uncheckedColor: "text-secondary", value: !addressIsResidential, onChange: function (v) {
                        setAddressIsResidential(!v);
                    } })),
            !addressIsResidential && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Business Name", 
                // lines={4}
                size: "small", borderColor: "border-gray-400", value: businessName, onChange: function (v) {
                    setBusinessName(v);
                } })),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Complex or Building Name, unit number or floor", size: "xsmall" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Complex/Building Name", lines: 4, size: "small", borderColor: "border-gray-400", value: complexOrBuildingDetails, onChange: function (v) {
                    setComplexOrBuildingDetails(v);
                } }))) : (react_1.default.createElement("div", { className: "" }, addressList && addressList.length > 0 ? (addressList.map(function (address, index) {
            return (react_1.default.createElement("div", { key: index, className: "".concat(index == 0 ? "" : "border-t border-borders", " mb-2 pt-2 flex flex-row gap-4 items-center") },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.label, bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.full_address })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-error", iconColor: "text-white", size: "xsmall", loading: index == deletingIndex && isDeleting, onClick: function () {
                            setIsDeleting(true);
                            setDeletingIndex(index);
                            (0, delete_address_1.delete_address)({
                                _id: address._id,
                            }).then(function (response) {
                                setIsDeleting(false);
                                setDeletingIndex(-1);
                                if (response.success) {
                                    getAddresses();
                                }
                                else {
                                    dispatch((0, main_1.setMainModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }))));
        })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You haven't added any addresses." }))))));
};
exports.ProfileAddressWidget = ProfileAddressWidget;
