"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignInPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var user_1 = require("../../reducers/slices/user");
var SignInPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var main = (0, react_redux_1.useSelector)(function (state) { return state.mainState; }).main;
    var _a = (0, react_1.useState)(""), emailMessage = _a[0], setEmailMessage = _a[1];
    var _b = (0, react_1.useState)(""), passwordMessage = _b[0], setPasswordMessage = _b[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { user: __assign(__assign({}, user.user), (_a = {}, _a[name] = value, _a)) })));
    };
    var validate = function () {
        var messageCount = 0;
        setEmailMessage("");
        setPasswordMessage("");
        if (!user.user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.user.password) {
            messageCount++;
            setPasswordMessage("Password is required.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-y-4 w-96 h-full justify-center" },
        react_1.default.createElement("div", { className: "text-3xl font-bold" }, "Sign in to Ackermans"),
        react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: user.user.email, errorText: emailMessage, onChange: function (v) {
                    onControlChanged("email", v);
                } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", type: "password", value: user.user.password, errorText: passwordMessage, onChange: function (v) {
                    onControlChanged("password", v);
                } }),
            react_1.default.createElement("div", { className: "flex flex-row justify-between items-center" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Remember me", value: user.user.remember_me, onChange: function (v) {
                        onControlChanged("remember_me", v);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Forgot password?", size: "small", onClick: function () {
                        dispatch((0, main_1.setDrawerPage)("forgot"));
                        // navigate(`${rootPath}/forgot-password`);
                    } })),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Sign in", loading: user.is_loading, backgroundColor: "bg-primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (validate()) {
                                dispatch((0, user_1.sign_in_thunk)());
                            }
                            return [2 /*return*/];
                        });
                    }); } }))),
        react_1.default.createElement("div", { className: " text-center" },
            "Don't have an account?",
            " ",
            react_1.default.createElement("span", { className: "hover:underline cursor-pointer text-primary", onClick: function () {
                    dispatch((0, main_1.setDrawerPage)("signup"));
                    // navigate(`${rootPath}/signup`);
                } }, "Sign up now"))));
};
exports.SignInPage = SignInPage;
